<template>
  <div class="contacts">
    <top-bar back backroute="support" title="Контакты"/>
    <div class="container">
      <div class="wrap">
        <div class="item">
          <a href="tel:+77767226292"></a>
          <div class="left">
            <div class="img">
              <n-icon icon="phone"/>
            </div>
            <div class="title">+7 776 722 62 92</div>
          </div>
        </div>

        <div class="item">
          <a href="https://wa.me/77767226292?text=Здравствуйте!%0AУ меня есть вопрос."></a>
          <div class="left">
            <div class="img">
              <n-icon icon="whatsapp"/>
            </div>
            <div class="title">+7 776 722 62 92</div>
          </div>
        </div>

        <div class="item">
          <n-link :to="{name: ''}"></n-link>
          <div class="left">
            <div class="img">
              <n-icon icon="mail"/>
            </div>
            <div class="title">pochta@pochta.kz</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contacts"
}
</script>

<style scoped lang="scss">
.contacts {
  .wrap {
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 18px 0;
      border-bottom: 1px solid #D9D9D9;
      position: relative;

      a {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
      }

      .img {
        margin-right: 8px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .left {
        display: flex;
        align-items: center;
      }
    }

    .title {
      font-weight: 400;
      font-size: 18px;
      color: #102E4F;
    }
  }
}
</style>
